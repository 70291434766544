import { Button, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react"
import Layout from "../../components/shared-layout";
import Breadcrumb from "../../components/shared-breadcrumb";
import useStores from "../../hooks/use-stores"
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import { navigate } from "gatsby";
import { PAGE_SIZE, ProdEnvCheck } from "../../constants/options";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";
import { Box } from "@mui/system";

const ExamPage = observer(() => {
  const { examStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAttemptsModal, setShowAttemptsModal] = useState<boolean>(false);
  const [currentExamId, setCurrentExamId] = useState<number>(0);
  const [sortMode, setSortMode] = useState<string>("0");
  const [attempList, setAttempList] = useState<any>([]);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    examStore.getExamList(PAGE_SIZE, 0);
  }, [])

  const getExamDetail = () => {
    return examStore.examList.data.find(e => e.id == currentExamId);
  }

  const onChangeMode = (event: SelectChangeEvent) => {
    setSortMode(event.target.value as string);
    // if (event === "0")
    //   setAttemptList(examStore.examList.find(e => e.id == currentExamId).examStudentAttempts);
  };

  return (
    <ContentLayout
      pageName={t('MY_EXAM')}
      pageHeading={t('MY_EXAM')}
      breadCrumb={[
        { label: t('EXAM'), to: "" },
        { label: t('MY_EXAM'), to: "/exam" }
      ]}
      showHeadingButton={false}
    >
      <VmTable
        loading={examStore.loading}
        page={page}
        paginationCount={examStore.examList.totalCount == 0 ? 1 : examStore.examList.totalCount / PAGE_SIZE}
        onChangePagination={(event, pageNumber) => {
          setPage(pageNumber);
          examStore.getExamList(PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
        }}
        thead={["ID", t('EXAM_CODE'), t('START_TIME'), t('END_TIME'), t('TIME_LIMIT'), t('ACTION')]}
        tbody={examStore.examList.data.length > 0 ?
          examStore.examList.data.map((exam: any, index: number) => (
            <>
              <Box marginY={1} />
              <tr key={`exam_list_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL}>{exam.examCode}</td>
                <td className={TD_NORMAL}>{exam.startTime.split('T')[0] + " " + exam.startTime.split('T')[1]}</td>
                <td className={TD_NORMAL}>{exam.endTime.split('T')[0] + " " + exam.endTime.split('T')[1]}</td>
                <td className={TD_NORMAL}>{exam.timeLimit}{exam.timeLimit > 1 ? t('MINUTES_B') : t('MINUTE_B')}</td>
                <td className={TD_LAST}>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    disabled={(exam.attemptAllow != 0 && exam.attemptAllow <= exam.examStudentAttempts.length) ||
                      new Date() > new Date(exam.endTime)}
                    onClick={() => {
                      setCurrentExamId(exam.id);
                      if (ProdEnvCheck([localStorage.ON_EXAM, localStorage.ON_EXAM_ATTEMPT])) {
                        navigate("/exam/on-exam", {
                          state: {
                            examId: exam.id, timeLimit: examStore.examList.data.find(e => e.id == exam.id).timeLimit
                          }
                        })
                      } else setShowModal(true);
                    }}
                  >
                    {exam.attemptAllow != 0 && exam.attemptAllow <= exam.examStudentAttempts.length
                      ? t('NO_MORE_ATTEMPTS') : ProdEnvCheck([localStorage.ON_EXAM, localStorage.ON_EXAM_ATTEMPT])
                        ? t('RESUME') : t('START_EXAM')}
                  </Button>
                  {exam.examStudentAttempts.length > 0 &&
                    <Button
                      variant="outlined"
                      sx={{ marginRight: 2 }}
                      color="success"
                      onClick={() => {
                        setCurrentExamId(exam.id);
                        setShowAttemptsModal(true);
                      }}
                    >
                      {t('VIEW_ATTEMPTS')}
                    </Button>}
                </td>
              </tr>
              <Box marginY={1} />
            </>
          ))
          : <tr><td colSpan={6}>{examStore.examList.totalCount < 1 && <Typography textAlign={"center"} sx={{ marginY: 1 }}>{t('NO_EXAM_FOUND')}</Typography>}</td></tr>}
      />

      {currentExamId != 0 &&
        <>
          <VmModal
            openModal={showModal}
            width={400}
            onClose={() => {
              setShowModal(false);
              setCurrentExamId(0);
            }}
            title={`${t('START_EXAM')}: ${getExamDetail().examCode}`}
            buttonLabel={t('CONFIRM_START')}
            buttonLabelWithoutConfirm={t('START_NOW')}
            onClickConfirmedButton={() => navigate("/exam/on-exam", { state: { examId: currentExamId, timeLimit: getExamDetail().timeLimit } })}
          >
            <Typography sx={{ marginBottom: 1, marginTop: -2, textDecoration: "underline" }} variant={"h6"}>
              {getExamDetail().examDescription}
            </Typography>
            <Typography sx={{ marginBottom: 1 }}>{t('READ_CAREFULLY')}</Typography>
            <div className="border rounded-lg p-1">
              <Typography variant="body2">
                {t('EXAM_FIRST_DP')}<span className="font-bold underline">{getExamDetail().timeLimit}{getExamDetail().timeLimit > 1 ? t('MINUTES_B') : t('MINUTE_B')}</span>.
              </Typography>
              <Typography variant="body2" sx={{ marginTop: 1.5 }}>
                {t('EXAM_SECOND_DP')}<span className="font-bold underline">{t('EXAM_SECOND_DP_CONTD')}</span>.
              </Typography>
              <Typography variant="body2" sx={{ marginTop: 1.5 }}>
                {t('EXAM_THIRD_DP')}<span className="font-bold underline text-red-500">0 {t('MARK_B')}</span>.
              </Typography>
            </div>
          </VmModal>
          <VmModal
            openModal={showAttemptsModal}
            width={600}
            onClose={() => {
              setShowAttemptsModal(false);
              setCurrentExamId(0);
            }}
            title={`${t('ATTEMPT_HISTORY')}: ${getExamDetail().examCode}`}
            showButton={false}
          >
            <div className="flex justify-between" style={{ marginTop: -10 }}>
              <Typography sx={{ marginBottom: 1, marginTop: 0, textDecoration: "underline" }} variant={"h5"}>
                {t('TOTAL_ATTEMPTS')}: {examStore.examList.data.find(e => e.id == currentExamId).
                  examStudentAttempts.length}
              </Typography>
              <div>
                <FormControl fullWidth>
                  <InputLabel>{t('SORT_BY')}</InputLabel>
                  <Select
                    label={t('SORT_BY')}
                    sx={{ width: 'fit-content', marginBottom: 1 }}
                    value={sortMode}
                    onChange={onChangeMode}
                    size="small"
                  >
                    <MenuItem value={"0"}>{t('LATEST')}</MenuItem>
                    <MenuItem value={"1"}>{t('EARLIEST')}</MenuItem>
                  </Select>
                </FormControl>
              </div>

            </div>
            <div className="max-h-96 overflow-auto">
              <VmTable
                page={0}
                loading={examStore.loading}
                thead={["ID", t('EXAM_DATE'), t('START_TIME'), t('MARK'), t('ACTION')]}
                tbody={examStore.examList.data.length > 0 && sortMode === "0" ?
                  examStore.examList.data.find(e => e.id == currentExamId).
                    examStudentAttempts.slice().reverse().map((attempt: any, index: number) => (
                      <tr key={`attempt_list_${index}`} className="border-b">
                        <td className="p-2">{index + 1}</td>
                        <td className="p-2">{attempt.startTime.split('T')[0]}</td>
                        <td className="p-2">{attempt.startTime.split('T')[1]}</td>
                        <td className="p-2">{attempt.result === "" ? 0 : attempt.result}</td>
                        <td className="p-2">
                          <Button
                            variant="outlined"
                            sx={{ marginRight: 2 }}
                            onClick={() =>
                              navigate("/exam/exam-attempt", {
                                state: { attemptId: attempt.id }
                              })
                            }
                          >
                            {t('VIEW')}
                          </Button>
                        </td>
                      </tr>
                    ))
                  : sortMode === "1" &&
                  examStore.examList.data.find(e => e.id == currentExamId).
                    examStudentAttempts.map((attempt: any, index: number) => (
                      <tr key={`attempt_list_${index}`} className="border-b">
                        <td className="p-2">{index + 1}</td>
                        <td className="p-2">{attempt.startTime.split('T')[0]}</td>
                        <td className="p-2">{attempt.startTime.split('T')[1]}</td>
                        <td className="p-2">{attempt.result === "" ? 0 : attempt.result}</td>
                        <td className="p-2">
                          <Button
                            variant="outlined"
                            sx={{ marginRight: 2 }}
                            onClick={() =>
                              navigate("/exam/exam-attempt", {
                                state: { attemptId: attempt.id }
                              })
                            }
                          >
                            {t('VIEW')}
                          </Button>
                        </td>
                      </tr>
                    ))}
              />
            </div>
          </VmModal>
        </>

      }
    </ContentLayout>
  )
});

export default ExamPage;
